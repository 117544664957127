<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { tologin } from "./utils/app";

export default {
  created() {
    this.$eventbus.$on("setFormCache", this.setFormCache);
    this.$eventbus.$on("getFormCache", this.getFormCache);
  },
  mounted() {
    // 监听 401 事件
    window.addEventListener(
      "AppLoginEvent",
      () => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );

    // 监听 403 事件
    window.addEventListener(
      "AppForbiddenEvent",
      (e) => {
        if (this.$route.path !== "/login") {
          tologin();
        }
      },
      false
    );
  },
  beforeDestroy() {
    this.$eventbus.$off("setFormCache", this.setFormCache);
    this.$eventbus.$off("getFormCache", this.getFormCache);
  },
  methods: {
    clear() {
      window.sessionStorage.clear();
      window.localStorage.clear();
    },
    setFormCache(data) {
      const name = this.$route.name;
      sessionStorage.setItem(`_app_form_cache_${name}`, JSON.stringify(data));
    },
    getFormCache() {
      const name = this.$route.name;
      const item = sessionStorage.getItem(`_app_form_cache_${name}`) || `{}`;
      return JSON.stringify(item);
    }
  }
};
</script>
<style lang="scss">
@import "./styles/app.scss";
</style>
